import { Image, View, XStack } from "tamagui";
import { CardFlip } from "../../../components/CardFlip";
import { ImageBackground } from "react-native";
import { ProfileCard } from "../../../components/ProfileCard";
import { graphql } from "relay-hooks";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { ProfileCardFlipQuery } from "./__generated__/ProfileCardFlipQuery.graphql";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";
import { Suspense, useEffect } from "react";
import { Loader } from "../../../components/design-system/Loader";

export const ProfileCardFlip = () => {
  const [queryRef, loadQuery] = useQueryLoader<ProfileCardFlipQuery>(
    ProfileCardFlipQueryString
  );

  const currentUser = useAtomValue(currentUserAtom);

  console.log(currentUser);

  useEffect(() => {
    if (currentUser?.id) {
      loadQuery({ id: currentUser.id });
    }
  }, [loadQuery, currentUser?.id]);

  return (
    <Suspense fallback={<Loader />}>
      {queryRef ? <ProfileCardFlipInternal queryRef={queryRef} /> : null}
    </Suspense>
  );
};

const ProfileCardFlipInternal = ({
  queryRef,
}: {
  queryRef: PreloadedQuery<ProfileCardFlipQuery>;
}) => {
  const data = usePreloadedQuery(ProfileCardFlipQueryString, queryRef);

  return (
    <CardFlip
      front={
        <View flex={1} w="100%" bg="$brandParchment">
          <ImageBackground
            style={{
              flex: 1,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              overflow: "hidden",
              borderRadius: 20,
            }}
            source={require("../../../../assets/pattern.png")}
          >
            <XStack
              flex={1}
              justifyContent="center"
              alignItems="center"
              w="100%"
            >
              <Image
                style={{
                  objectFit: "contain",
                  height: "90%",
                  width: "75%",
                }}
                resizeMode="contain"
                source={require("../../../../assets/card-back.png")}
              />
            </XStack>
          </ImageBackground>
        </View>
      }
      back={
        <View flex={1} w="100%" justifyContent="center" alignItems="center">
          <ProfileCard
            fragmentRef={data.profilesCollection?.edges[0].node ?? null}
          />
        </View>
      }
      isProfileLoaded={!!data.profilesCollection?.edges[0].node}
    />
  );
};

export const ProfileCardFlipQueryString = graphql`
  query ProfileCardFlipQuery($id: UUID!) {
    profilesCollection(filter: { id: { eq: $id } }, first: 1) {
      edges {
        node {
          ...ProfileCard_fragment
        }
      }
    }
  }
`;

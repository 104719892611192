import { KeyboardAvoidingView } from "react-native";
import { Heading, Paragraph, View, YStack } from "tamagui";
import { Logo } from "../../components/Logo";
import { Button, Input } from "../../components/design-system";
import { useEffect, useState } from "react";
import { supabase } from "../../../lib/supabase";
import { SafeAreaView } from "react-native-safe-area-context";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { OnboardingNavigatorParamList } from "../../navigators/OnboardingNavigator";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import { customColorTokens } from "../../../tamagui.config";

export const Phone = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const navigation =
    useNavigation<NavigationProp<OnboardingNavigatorParamList>>();

  const validatePhoneNumber = (number: string) => {
    const phoneNumber = parsePhoneNumber(number, "US");
    if (phoneNumber) {
      return (
        phoneNumber.isValid() &&
        phoneNumber.country === "US" &&
        phoneNumber.isPossible()
      );
    }
  };

  useEffect(() => {
    if (validatePhoneNumber(phoneNumber) && phoneNumber.length >= 10) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  }, [phoneNumber, setIsPhoneValid]);

  const sendCode = async () => {
    const phone = parsePhoneNumber(phoneNumber, "US");
    console.log(phone);

    const formattedPhone = phone?.formatInternational();

    console.log(formattedPhone);

    console.log(formattedPhone?.replace(/\s/g, ""));
    if (formattedPhone) {
      const { error } = await supabase.auth.signInWithOtp({
        phone: formattedPhone.replace(/\s/g, ""),
      });

      if (error) {
        alert(error.message);
      } else {
        navigation.navigate("Code", { phone: formattedPhone });
      }
    }
  };

  const asYouType = new AsYouType("US");
  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: customColorTokens.brandParchment }}
    >
      <YStack
        justifyContent="space-between"
        flex={1}
        alignItems="flex-start"
        p="$4"
        w="100%"
        space={10}
      >
        <View w="100%" alignItems="center" justifyContent="center">
          <Logo />
          <Heading w="100%" size={"$2"} mt={32} textTransform="uppercase">
            Enter your phone number
          </Heading>
        </View>

        <KeyboardAvoidingView
          behavior="padding"
          enabled
          keyboardVerticalOffset={80}
          style={{ width: "100%", flex: 1 }}
        >
          <YStack space={4} w="100%" flex={1} justifyContent="space-between">
            <View justifyContent="center">
              <Input
                keyboardType="numeric"
                placeholder="Enter Phone"
                onChangeText={(text) => setPhoneNumber(text)}
                value={asYouType.input(phoneNumber)}
              />
              <Paragraph mt="$2" fontSize={12}>
                We will send you a one time SMS message. Carrier charges may
                apply.
              </Paragraph>
            </View>
            <Button
              disabled={!isPhoneValid}
              onPress={() => sendCode()}
              w="100%"
              opacity={isPhoneValid ? 1 : 0.5}
            >
              CONTINUE
            </Button>
          </YStack>
        </KeyboardAvoidingView>
      </YStack>
    </SafeAreaView>
  );
};

import { KeyboardAvoidingView, Pressable } from "react-native";
import { Heading, Text, View, YStack } from "tamagui";
import { Logo } from "../../components/Logo";
import { Button, Input } from "../../components/design-system";
import { useEffect, useState } from "react";
import { supabase } from "../../../lib/supabase";
import { SafeAreaView } from "react-native-safe-area-context";
import { currentUserAtom, isAuthenticatedAtom } from "../../../atoms/global";
import { useSetAtom } from "jotai";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { OnboardingNavigatorParamList } from "../../navigators/OnboardingNavigator";
import { BaseNavigatorParamList } from "../../navigators/BaseNavigator";
import { customColorTokens } from "../../../tamagui.config";
import { iProfile } from "../../features/Profile";

export const Code = () => {
  const [code, setCode] = useState("");

  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();

  const route = useRoute<RouteProp<OnboardingNavigatorParamList, "Code">>();
  const phone = route.params.phone;
  const setCurrentUser = useSetAtom(currentUserAtom);

  const setIsAuthenticated = useSetAtom(isAuthenticatedAtom);

  const verifyCode = async () => {
    if (phone && code.length === 6) {
      phone as unknown as string;
      supabase.auth
        .verifyOtp({
          phone: phone as unknown as string,
          token: code,
          type: "sms",
        })
        .then(async ({ error, data }) => {
          if (error) {
            console.log(error, data);
            alert(error.message);
          } else {
            if (data.user?.id) {
              const profile: iProfile | null = await fetchProfile(
                data.user?.id
              );
              console.log(profile, data.user.id);

              setCurrentUser(data.user);

              if (profile?.name) {
                setIsAuthenticated(true);
              } else {
                navigation.navigate("Onboarding", {
                  screen: "Name",
                });
              }
            }
          }
        });
    }
  };

  const fetchProfile = async (id: string) => {
    try {
      if (id) {
        // Only fetch if an ID is provided
        const response = await supabase
          .from("profiles")
          .select("*")
          .eq("id", id)
          .single();

        if (response.error) {
          return null;
        }

        return response.data;
      }
    } catch (err) {
      return null;
    }
  };

  const resendCode = async () => {
    const phoneNumber = (phone as unknown as string).replace(/[^\d]/g, "");
    await supabase.auth.resend({
      type: "sms",
      phone: phoneNumber,
    });
  };

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: customColorTokens.brandParchment }}
    >
      <YStack
        justifyContent="space-between"
        flex={1}
        alignItems="flex-start"
        p="$4"
        w="100%"
        space={10}
      >
        <View w="100%" alignItems="center" justifyContent="center">
          <Logo />
          <Heading w="100%" size={"$2"} mt={32} textTransform="uppercase">
            ENTER THE CODE WE SENT
          </Heading>
        </View>

        <KeyboardAvoidingView
          behavior="padding"
          enabled
          keyboardVerticalOffset={80}
          style={{ width: "100%", flex: 1 }}
        >
          <YStack w="100%" flex={1} justifyContent="space-between">
            <View justifyContent="flex-start" flex={1}>
              <Input
                keyboardType="numeric"
                onChangeText={(text) => setCode(text)}
                placeholder="Enter Code"
                autoFocus
              />
              <Pressable onPress={resendCode}>
                <Text mt="$2" fontSize={12}>
                  If you didn’t receive a code, tap here.
                </Text>
              </Pressable>
            </View>
            <Button w="100%" disabled={code.length < 6} onPress={verifyCode}>
              CONTINUE
            </Button>
          </YStack>
        </KeyboardAvoidingView>
      </YStack>
    </SafeAreaView>
  );
};

import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";
import {
  ClubScreenQueryRenderer,
  ClubScreenQueryString,
} from "./ClubScreenQueryRenderer";
import { ClubScreenQueryRendererQuery } from "./__generated__/ClubScreenQueryRendererQuery.graphql";
import { useQueryLoader } from "react-relay";
import { Suspense, useEffect } from "react";
import { Loader } from "../../../components/design-system/Loader";

export const ClubScreen = () => {
  const user = useAtomValue(currentUserAtom);

  const [queryRef, loadQuery] = useQueryLoader<ClubScreenQueryRendererQuery>(
    ClubScreenQueryString
  );

  useEffect(() => {
    if (user?.id) {
      loadQuery({ id: user.id });
    }
  }, [loadQuery, user?.id]);

  return (
    <Suspense fallback={<Loader />}>
      {queryRef ? <ClubScreenQueryRenderer queryRef={queryRef} /> : null}
    </Suspense>
  );
};

import { SafeAreaView } from "react-native-safe-area-context";
import { brandShadow, customColorTokens } from "../../../../tamagui.config";
import { Button, ScrollView, Text, View, XStack } from "tamagui";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import {
  NavigationProp,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import {
  ArrowLeftIcon,
  PeopleIcon,
} from "../../../components/design-system/icons";
import { supabase } from "../../../../lib/supabase";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";
import { useCallback, useEffect, useState } from "react";
import { iProfile } from "../../../features/Profile";
import { Pressable } from "react-native";
import Avatar from "../../../components/Avatar";
import { ClubNavigatorParamList } from "../../../navigators/ClubNavigator";
import { Loader } from "../../../components/design-system/Loader";
import { useGetFriendsList } from "../../../hooks/useGetFriendsList";
import { UserPlus, UserPlus2, UserRoundPlus } from "@tamagui/lucide-icons";

type FriendRequest = {
  status: "pending" | "accepted";
  friend: iProfile;
  id: string;
};

export const FriendsAndRequests = () => {
  const [friendRequests, setFriendRequests] = useState<FriendRequest[]>([]);
  const currentUser = useAtomValue(currentUserAtom);
  const isFocused = useIsFocused();

  const [loading, setLoading] = useState(false);

  const fetchFriendRequests = useCallback(async () => {
    const { data } = await supabase
      .from("friendships")
      .select("*, profiles!friendships_user_id_fkey(*)")
      .eq("status", "pending")
      .eq("friend_id", currentUser?.id ?? "");

    const requests = data?.map((d) => ({
      id: d.id,
      status: d.status,
      friend: d.profiles,
    }));

    setFriendRequests(requests ?? []);
  }, [setFriendRequests]);

  const { friends, setFriends, fetchFriends } = useGetFriendsList(
    currentUser?.id ?? ""
  );

  useEffect(() => {
    if (isFocused) {
      fetchFriendRequests();
    }
  }, [isFocused, fetchFriendRequests]);

  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  const handleAccept = async (requestId: string) => {
    setLoading(true);
    const { error } = await supabase
      .from("friendships")
      .update({ status: "accepted" })
      .eq("id", requestId);

    if (error) {
      console.error("Error accepting friend request:", error);
      return;
    }

    const request = friendRequests.find((r) => r.id === requestId);

    const newRequests = friendRequests.filter((r) => r.id !== requestId);

    setFriendRequests(newRequests);

    const newFriendsList = [
      ...friends,
      { ...request, status: "accepted" } as FriendRequest,
    ];
    setFriends(newFriendsList);

    setLoading(false);
  };

  const handleRemove = async (requestId: string) => {
    setLoading(true);
    const { error } = await supabase
      .from("friendships")
      .delete()
      .eq("id", requestId);

    if (error) {
      console.error("Error removing friend request:", error);
      return;
    }

    setLoading(false);
    fetchFriendRequests();
    fetchFriends();
  };

  return (
    <View flex={1} px="$4">
      <View>
        <View py="$4" justifyContent="center">
          <Text fontSize={16} fontFamily="$heading" textTransform="uppercase">
            Friend Requests
          </Text>
        </View>
        <View flex={1} pos="relative">
          {friendRequests.length === 0 ? (
            <View
              alignItems="flex-start"
              justifyContent="center"
              gap="$1"
              py="$2"
            >
              <Text>{`You don't have any friend requests`}</Text>
            </View>
          ) : (
            friendRequests.map((request) => (
              <FriendRow
                key={request.friend.id}
                {...request}
                hideAccept={false}
                onAccept={() => handleAccept(request.id)}
                onRemove={() => handleRemove(request.id)}
              />
            ))
          )}
        </View>
      </View>

      <View>
        <XStack py="$4" alignItems="center" justifyContent="space-between">
          <Text fontSize={16} fontFamily="$heading" textTransform="uppercase">
            Friends
          </Text>
          <Pressable onPress={() => navigation.navigate("Members")}>
            <UserRoundPlus />
          </Pressable>
        </XStack>
        <View flex={1} pos="relative">
          {friends.map((f) => (
            <FriendRow
              key={f.friend.id}
              {...f}
              hideAccept
              onAccept={() => handleAccept(f.id)}
              onRemove={() => handleRemove(f.id)}
            />
          ))}
        </View>
      </View>
    </View>
  );
};

const FriendRow = (
  props: FriendRequest & {
    hideAccept?: boolean;
    onAccept: () => void;
    onRemove: () => void;
  }
) => {
  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  return (
    <View key={props.friend.id} py="$2">
      <XStack justifyContent="space-between" alignItems="center">
        <Pressable
          onPress={() =>
            navigation.navigate("Member", {
              id: props.friend.id ?? "",
            })
          }
        >
          <XStack gap="$2" alignItems="center">
            <Avatar
              url={props.friend.avatar_url ?? null}
              size={40}
              imageStyle={{
                borderRadius: 20,
              }}
            />
            <Text>{props.friend.name}</Text>
          </XStack>
        </Pressable>

        <XStack alignItems="center" gap="$2">
          <Button
            size="$2"
            fontSize={12}
            bg="red"
            color="white"
            fontFamily={"$heading"}
            onPress={() => props.onRemove()}
            pressStyle={{
              bg: "red",
              shadowColor: "$colorTransparent",
              shadowOffset: { width: 0, height: 0 },
              borderWidth: 1,
              borderColor: "black",
              scale: 0.98,
            }}
            borderWidth={1}
            borderColor={"black"}
            {...brandShadow}
          >
            REMOVE
          </Button>
          {props.hideAccept ? null : (
            <Button
              size="$2"
              fontSize={12}
              bg="white"
              borderWidth={1}
              borderColor="black"
              onPress={() => props.onAccept()}
              pressStyle={{
                bg: "$white",
                shadowColor: "$colorTransparent",
                shadowOffset: { width: 0, height: 0 },
                borderWidth: 1,
                borderColor: "black",
                scale: 0.98,
              }}
              {...brandShadow}
            >
              ACCEPT
            </Button>
          )}
        </XStack>
      </XStack>
    </View>
  );
};

import { Modal } from "react-native";
import { rideAtom } from "../atoms";
import { useAtom } from "jotai";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Text, View, XStack } from "tamagui";
import { HeaderButton } from "../../design-system/Button/HeaderButton";
import { ArrowLeftIcon } from "../../design-system/icons";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";

type LocationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const LocationModal = ({ isOpen, onClose }: LocationModalProps) => {
  const [ride, setRide] = useAtom(rideAtom);

  const handleLocationPress = (location: string) => {
    setRide({
      ...ride,
      location,
    });

    onClose();
  };

  const { top } = useSafeAreaInsets();
  return (
    <Modal
      visible={isOpen}
      animationType="fade"
      transparent={false}
      onRequestClose={onClose}
    >
      <View flex={1} paddingTop={top} bg="$brandParchment">
        <View px="$4" justifyContent="flex-start" alignItems="flex-start">
          <XStack
            justifyContent="space-between"
            w="100%"
            mb="$5"
            alignItems="flex-start"
          >
            <View>
              <HeaderButton onPress={() => onClose()}>
                <ArrowLeftIcon color="black" width={16} height={16} />
              </HeaderButton>
            </View>
          </XStack>
        </View>

        <View px="$4" flex={1}>
          <Text
            fontSize={16}
            fontFamily="$heading"
            textTransform="uppercase"
            mb="$4"
          >
            Choose a location
          </Text>
          <GooglePlacesAutocomplete
            keepResultsAfterBlur={true}
            onFail={(error) => console.error(error)}
            placeholder="Search A Location"
            fetchDetails={true}
            onPress={(data, details) => {
              console.log(data, details);
              if (details?.formatted_address)
                handleLocationPress(details?.formatted_address);
            }}
            requestUrl={{
              url: "https://rides.breakfastclubatx.com/api/maps",
              useOnPlatform: "web",
            }}
            query={{
              key: "AIzaSyBaT5yZPPpGIYcONyuBofkVyutJ6Jf3eA0",
            }}
            key="AIzaSyBaT5yZPPpGIYcONyuBofkVyutJ6Jf3eA0"
            renderRow={(rowData, i) => {
              return <Text>{rowData.description}</Text>;
            }}
            styles={{
              container: {},

              textInput: {
                borderWidth: 1,
                paddingVertical: 20,
                paddingHorizontal: 12,
                fontSize: 18,
                height: 60,
                fontFamily: "Souvenir",
                color: "black",
              },
              listView: {
                color: "black",
              },

              separator: {
                borderBottomColor: "black",
                borderBottomWidth: 1,
              },
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

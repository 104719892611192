import { SafeAreaView } from "react-native-safe-area-context";
import { Heading, ScrollView, Text, View, XStack } from "tamagui";
import { customColorTokens } from "../../../../tamagui.config";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { ClubNavigatorParamList } from "../../../navigators/ClubNavigator";
import { CloseIcon } from "../../../components/design-system/icons";
import { Header } from "../../../components/design-system/Header";
import { Cog, Settings } from "@tamagui/lucide-icons";
import { FriendsAndRequests } from "../FriendsAndRequests/FriendsAndRequests";
import { ProfileCardFlip } from "./ProfileCardFlip";

export const ProfileScreen = () => {
  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();
  return (
    <ScrollView backgroundColor="$brandParchment">
      <SafeAreaView
        style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
      >
        <View
          px="$4"
          pb="$4"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <XStack justifyContent="space-between" w="100%">
            <HeaderButton onPress={() => navigation.navigate("ClubScreen")}>
              <CloseIcon width={16} height={16} />
            </HeaderButton>
            <HeaderButton onPress={() => navigation.navigate("EditProfile")}>
              <Settings width={16} height={16} />
            </HeaderButton>
          </XStack>
        </View>
        <View
          bg="$brandYellow"
          py="$4"
          px="$4"
          borderTopWidth={1}
          w="100%"
          borderBottomWidth={1}
        >
          <Heading size="$4" textTransform="uppercase" fontFamily="$heading">
            Profile
          </Heading>
        </View>
        <View flex={1} py="$4">
          <ProfileCardFlip />
        </View>
        <FriendsAndRequests />
      </SafeAreaView>
    </ScrollView>
  );
};
